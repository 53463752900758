<template>
    <v-dialog
            v-model="show"
            max-width="40%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="primary">
                <v-icon left>
                    mdi-account-plus
                </v-icon>
                Create New User
            </v-btn>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red" >mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                Create New User
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text>
                <v-form
                        ref="inviteNewUserForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="user.name"
                            label="Full Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-text-field
                            v-model="user.email"
                            label="E-mail"
                            :rules="rules.emailRules"
                            required>
                    </v-text-field>
                    <v-select
                            v-model="user.userRole"
                            :items="roles"
                            :rules="[v => !!v || 'User role is required']"
                            label="User Role"
                            required>
                    </v-select>
                    <v-select
                            v-model="user.assignedKiosks"
                            :rules="[v => !!v || 'Assign at-least one kiosk']"
                            :items="kiosks"
                            @keyup.enter="inviteNewUser"
                            label="Assigned Kiosks"
                            required
                            multiple>
                        <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span
                                    v-if="index === 1"
                                    class="grey--text caption">
                                (+{{ user.assignedKiosks.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>

                    <v-btn color="primary"
                           dark
                           block
                           large
                           @keyup.enter="inviteNewUser"
                           @click="inviteNewUser"
                           >
                        Create and Invite User
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text align="center" >
                <p>Invitation link sent to the user's email will be use to set the password and activate the account.</p>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
      name: 'createNewUser',
      data(){
        return{
          show: null,
          loading: false,
          user:{
            name: '',
            email: '',
            userRole: '',
            assignedKiosks: [],
            fdn: window.location.hostname,
          },
          rules:{
            nameRules: [
              v => !!v || 'Full name is required',
            ],
            emailRules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
          }
        }
      },
      methods:{
        inviteNewUser(){
          if(this.$refs.inviteNewUserForm.validate()){
            this.loading = true;
            this.$store.dispatch('inviteNewUser', this.user)
              .then(response => {
                this.loading = false;
                this.show = false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'green'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }
        }
      },
      computed:{
        ...mapGetters(['userRoles', 'allRegisteredKiosks']),
        roles(){
          let roleName = [];
          this.userRoles.forEach(role => roleName.push(role.data.name));
          return roleName;
        },
        kiosks(){
          let kioskName = [];
          this.allRegisteredKiosks.forEach(kiosk => kioskName.push(kiosk.data().name))
          return kioskName;
        }
      },
      mounted(){
        if(this.userRoles.length === 0){
          this.$store.dispatch('getUserRoles')
            .then((response) => {
              console.log(response)
            }, error => {
              console.error(error);
            })
        }
        if(this.allRegisteredKiosks.length === 0){
          this.$store.dispatch('getAllRegisteredKiosks')
            .then((response) => {
              console.log(response)
            }, error => {
              console.error(error);
            })
        }
      }
    }
</script>