<template>
    <v-container
            fluid >
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-h4">
                User Management
                <v-spacer></v-spacer>
                <createNewUser></createNewUser>
            </v-card-title>
            
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >
            <v-tabs v-model="tab">
                <v-tab>Registered Users</v-tab>
                <v-tab>Invited Users</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">

                <v-tab-item>
                    <v-card-title>
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Users by Name"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                            :loading="loading"
                            :headers="registeredUsersHeaders"
                            :items="allRegisteredUsers"
                            :search="search">
                        <template v-slot:item.name="{item}">
                            {{item.data().name}}
                        </template>
                        <template v-slot:item.email="{item}">
                            {{item.data().email}}
                        </template>
                        <template v-slot:item.userRole="{item}">
                            {{item.data().userRole}}
                        </template>
                        <template v-slot:item.assignedKiosks="{item}">
                            <div v-for="(kiosk, index) in item.data().assignedKiosks" :key="index">
                                <v-chip small v-if="index === 0">
                                    <span>{{kiosk}}</span>
                                </v-chip>
                                <span v-if="index === 1"
                                      class="grey--text caption">
                                    (+ more..)
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.last_changed="{item}">                          
                            {{item.data().last_changed | formatDate}}
                        </template>
                        <template v-slot:item.createdOn="{item}">
                            {{item.data().createdOn | formatDate}}
                        </template>
                        <template v-slot:item.onlineStatus="{item}">
                            <v-chip
                                    dark
                                    small
                                    :color="getColor(item.data().onlineStatus)">
                                {{item.data().onlineStatus}}
                            </v-chip>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <editAndDeleteUser
                                    userType="registered"
                                    :userIndex="item">
                            </editAndDeleteUser>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-card-title>
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Users by Name"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                            :loading="loading"
                            :headers="invitedUsersHeaders"
                            :items="allInvitedUsers"
                            :search="search">
                        <template v-slot:item.name="{item}">
                            {{item.data().name}}
                        </template>
                        <template v-slot:item.email="{item}">
                            {{item.data().email}}
                        </template>
                        <template v-slot:item.userRole="{item}">
                            {{item.data().userRole}}
                        </template>
                        <template v-slot:item.assignedKiosks="{item}">
                            <div v-for="(kiosk, index) in item.data().assignedKiosks" :key="index">
                                <v-chip small v-if="index === 0">
                                    <span>{{kiosk}}</span>
                                </v-chip>
                                <span v-if="index === 1"
                                      class="grey--text caption">
                                    (+ more..)
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.createdOn="{item}">
                            {{item.data().createdOn | formatDate}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <editAndDeleteUser
                                    userType="invited"
                                    :userIndex="item">
                            </editAndDeleteUser>
                        </template>
                        <template v-slot:item.inviteLink="{ item }">
                            <v-btn
                                    small
                                    color="primary"
                                    text
                                    @click="copyLink(item)">
                                <v-icon left small>mdi-content-copy</v-icon>
                                Copy Link
                            </v-btn>
                            <input type="hidden" :id="'user' + item.id"  :value=" registrationLink + item.id">
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    import moment from 'moment'
    import createNewUser from '@/components/dashboard/admin/createNewUser.vue';
    import editAndDeleteUser from '@/components/dashboard/admin/editAndDeleteUser.vue';

    export default {
      name: 'userManagement',
      components:{
        createNewUser,
        editAndDeleteUser
      },
      data () {
        return {
          tab: null,
          loading:true,
          search: '',
          registrationLink: window.location.hostname + '/registration/',
          registeredUsersHeaders: [
            {text: 'Name', align: 'start', value: 'name'},
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'userRole' },
            { text: 'Assigned Kiosks', value: 'assignedKiosks' },
            { text: 'Status', value: 'onlineStatus' },
            { text: 'Last Online', value: 'last_changed'},
            { text: 'Joined', value: 'createdOn'},
            { text: 'Actions', value: 'actions', sortable: false },
          ],
          invitedUsersHeaders: [
            {text: 'Name', align: 'start', value: 'name'},
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'userRole' },
            { text: 'Assigned Kiosks', value: 'assignedKiosks' },
            { text: 'Invited On', value: 'createdOn' },
            { text: 'Actions', value: 'actions', sortable: false },
            { text: 'Invitation Link', value: 'inviteLink', sortable: false },
          ],
        }
      },
      computed:{
        ...mapGetters(['allRegisteredUsers','allInvitedUsers']),
      },
      methods:{
        getColor(status){
          if (status === 'Unavailable') return 'error';
          if (status === 'Busy') return 'amber';
          if (status === 'Available') return 'success'
        },
        copyLink(user){
          let link = document.querySelector('#user'+ user.id);
          link.setAttribute('type', 'text');
          link.select();

          if(document.execCommand('copy')){
            this.$store.dispatch('showSnackBar', {
              text: 'Invitation link copied successfully',
              color: 'success'
            })
          }
          else{
            this.$store.dispatch('showSnackBar', {
              text: 'Link could not be copied.',
              color: 'error'
            })
          }
          link.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        }
      },
      mounted(){
        this.$store.dispatch('getAllRegisteredUsers')
          .then(() => {
            this.loading = false;
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error',
              timeout: 0,
            })
          });
        this.$store.dispatch('getAllInvitedUsers')
          .then(() => {
            this.loading = false;
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error',
              timeout: 0,
            })
          });
      },
      destroyed(){
        //Todo - end snapshot to all user profiles
      },
      filters:{
        formatDate(val){
          if(!val) {return '-'}
          let date = val.toDate();
          return moment(date).fromNow();
        },
      },
    }
</script>